import { flexCenterSx, marginBottom16Sx } from '../../styles/Global.style';

export const footerContainerSx = {
  ...flexCenterSx,
  flexDirection: 'column',
  textAlign: 'center',
  height: '190px',
  paddingTop: '80px',
};

export const copyRightSx = { fontSize: '10px !important', ...marginBottom16Sx };
