import { Box } from '@mui/material';
import { FEATURES } from '../../constants/features';
import { Feature } from '../Feature/Feature';

export const FeatureList = () => {
  return (
    <Box component="div" sx={{ display: 'flex', flexFlow: 'row wrap' }}>
      {FEATURES.map((f, i) => (
        <Feature
          key={i}
          icon={f.icon}
          heading={f.heading}
          content={f.content}
        />
      ))}
    </Box>
  );
};
