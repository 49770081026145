import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { Home } from '../pages/Home/Home';
import { APP_PATHS } from '../constants/paths';
import { Events } from '../pages/Events/Events';
import { Gallery } from '../pages/Gallery/Gallery';
import { Calendar } from '../pages/Calendar/Calendar';
import { Volunteer } from '../pages/Volunteer/Volunteer';
import { Help } from '../pages/Help/Help';
import { Feedback } from '../pages/Feedback/Feedback';
import { Fundraising } from '../pages/Fundraising/Fundraising';
import { About } from '../pages/About/About';

export const Routes = () => (
  <Switch>
    <Route path={APP_PATHS.home} element={<Home />} />
    <Route path={APP_PATHS.events} element={<Events />} />
    <Route path={APP_PATHS.volunteer} element={<Volunteer />} />
    <Route path={APP_PATHS.gallery} element={<Gallery />} />
    <Route path={APP_PATHS.fundraising} element={<Fundraising />} />
    <Route path={APP_PATHS.help} element={<Help />} />
    <Route path={APP_PATHS.calendar} element={<Calendar />} />
    <Route path={APP_PATHS.feedback} element={<Feedback />} />
    <Route path={APP_PATHS.about} element={<About />} />
    <Route path="*" element={<Navigate to={APP_PATHS.home} replace />} />
  </Switch>
);
