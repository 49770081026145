export const SCHOOL_LINK =
  'http://www.baljaffray.e-dunbarton.sch.uk/get-in-touch/';

export const EASYFUNDRAISING =
  'https://www.easyfundraising.org.uk/causes/baljaffrayprimary/';

export const LOTTERY =
  'https://www.yourschoollottery.co.uk/lottery/school/baljaffray-primary-school';

export const CHIPPIE = 'https://www.ticketsource.co.uk/null/t-rpxppnv';
