import { Box, useTheme } from '@mui/material';
import { Button } from '../../components/Button/Button';
import { Copy } from '../../copy/Copy';
import { buttonSx } from '../../styles/Global.style';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { FeatureList } from '../../components/FeatureList/FeatureList';
import { splashImgDimensions } from './Home.style';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { Loading } from '../../components/Loading/Loading';
import { useImgLoading } from '../../hooks/useImgLoading/useImgLoading';

export const Home = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoading, onImgLoad } = useImgLoading();

  return (
    <>
      {isLoading && <Loading />}

      <Box sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        <ContentCard
          img="/assets/img/splash.webp"
          imgDimensions={splashImgDimensions}
          onImgLoad={onImgLoad}
          title={Copy.generate('Home.splash.title')}
          heading={Copy.inject('Home.splash.heading', {
            pta: dynamicTextColor('PTA.', theme.palette.quaternary.main),
          })}
          content={Copy.generate('Home.splash.content')}
          isTop
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.about')}
              onClick={() => navigate(APP_PATHS.about)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          title={Copy.generate('Home.features.title')}
          heading={Copy.inject('Home.features.heading', {
            together: dynamicTextColor(
              'working together.',
              theme.palette.quaternary.main,
            ),
          })}
          isSecondaryCard
          isColumn
        >
          <FeatureList />
        </ContentCard>

        <ContentCard
          img="/assets/img/calendar.webp"
          title={Copy.generate('Home.calendar.title')}
          heading={Copy.inject('Home.calendar.heading', {
            on: dynamicTextColor(`what's on`, theme.palette.quaternary.main),
          })}
          content={Copy.generate('Home.calendar.content')}
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.calendar')}
              onClick={() => navigate(APP_PATHS.calendar)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          img="/assets/img/fundraise.webp"
          title={Copy.generate('Home.fundraise.title')}
          heading={Copy.inject('Home.fundraise.heading', {
            support: dynamicTextColor(
              `support our students.`,
              theme.palette.quaternary.main,
            ),
          })}
          content={Copy.generate('Home.fundraise.content')}
          isSecondaryCard
          isReversed
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.fundraise')}
              onClick={() => navigate(APP_PATHS.fundraising)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          img="/assets/img/events.webp"
          title={Copy.generate('Home.events.title')}
          heading={Copy.inject('Home.events.heading', {
            events: dynamicTextColor(
              `upcoming events`,
              theme.palette.quaternary.main,
            ),
          })}
          content={Copy.generate('Home.events.content')}
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.events')}
              onClick={() => navigate(APP_PATHS.events)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          img="/assets/img/gallery.webp"
          title={Copy.generate('Home.gallery.title')}
          heading={Copy.inject('Home.gallery.heading', {
            events: dynamicTextColor('events', theme.palette.quaternary.main),
          })}
          content={Copy.generate('Home.gallery.content')}
          isSecondaryCard
          isReversed
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.gallery')}
              onClick={() => navigate(APP_PATHS.gallery)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>

        <ContentCard
          heading={Copy.inject('Home.contactUs.heading', {
            contact: dynamicTextColor(
              'Get in touch.',
              theme.palette.quaternary.main,
            ),
          })}
          isColumn
        >
          <Box>
            <Button
              text={Copy.generate('Buttons.contact')}
              onClick={() => navigate(APP_PATHS.volunteer)}
              sx={buttonSx(theme)}
            />
          </Box>
        </ContentCard>
      </Box>
    </>
  );
};
