export const appBarSx = {
  height: '90px',
  padding: '16px',
  boxShadow: 'none',
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%)',
};

export const toolbarSx = {
  display: 'flex',
  maxWidth: '1100px',
  width: '100%',
  margin: '0 auto',
};

export const burgerSx = {
  flex: 1,
  justifyContent: 'right',
  display: 'flex',
};

export const navListContainerSx = {
  marginLeft: 'auto',
  width: '580px',
};

export const navListSx = {
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
};

export const navListItemSx = {
  margin: '0 16px',
};

export const logoSx = {
  textAlign: 'center',
  height: 65,
  width: 150,
};
