import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Button } from '../../components/Button/Button';
import { buttonSx } from '../../styles/Global.style';
import { EASYFUNDRAISING, LOTTERY } from '../../constants/links';

export const Fundraising = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Fundraising.intro.title')}
        heading={Copy.inject('Fundraising.intro.heading', {
          contribute: dynamicTextColor('you can contribute.', quaternary),
        })}
        content={Copy.generate('Fundraising.intro.content')}
        isColumn
        isTop
      />

      <ContentCard
        img="/assets/img/easy.webp"
        title={Copy.generate('Fundraising.easy.title')}
        heading={Copy.inject('Fundraising.easy.heading', {
          brands: dynamicTextColor(
            `brands donate.`,
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Fundraising.easy.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.signUp')}
            onClick={() => window.open(EASYFUNDRAISING)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/lottery.webp"
        title={Copy.generate('Fundraising.lottery.title')}
        heading={Copy.inject('Fundraising.lottery.heading', {
          funds: dynamicTextColor(
            `funds for our school.`,
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Fundraising.lottery.content')}
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.buyTickets')}
            onClick={() => window.open(LOTTERY)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>
    </>
  );
};
