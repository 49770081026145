import { Box } from '@mui/material';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';
import { iframeSx } from './GoogleCalendar.style';
import { flexCenterSx } from '../../styles/Global.style';

export const GoogleCalendar = () => {
  const { isMobile } = useIsMobile();

  return !isMobile ? (
    <Box sx={flexCenterSx}>
      <iframe
        title="Baljaffray PTA Desktop Google Calendar"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&showTz=0&showPrint=0&showCalendars=0&showTabs=0&hl=en_GB&src=YmFsamFmZnJheS5wdGEuZXZlbnRzQGdtYWlsLmNvbQ&color=%23039BE5"
        style={{ borderWidth: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
      />
    </Box>
  ) : (
    <Box sx={iframeSx}>
      <iframe
        title="Baljaffray PTA Mobile Google Calendar"
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&showPrint=0&showTabs=0&showCalendars=0&showTz=0&mode=AGENDA&showDate=0&showNav=0&src=YmFsamFmZnJheS5wdGEuZXZlbnRzQGdtYWlsLmNvbQ&color=%23039BE5"
        style={{ borderWidth: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
      />
    </Box>
  );
};
