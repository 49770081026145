export const APP_PATHS = {
  home: '/',
  events: '/events',
  volunteer: '/volunteer',
  gallery: '/gallery',
  calendar: '/calendar',
  help: '/help',
  feedback: '/feedback',
  fundraising: '/fundraising',
  about: '/about',
};
