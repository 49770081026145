import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { useTheme } from '@mui/material';
import { flexCenterSx } from '../../styles/Global.style';

export const Loading = () => {
  const { windowSize } = useWindowSize();
  const theme = useTheme();

  return (
    <Box sx={{ ...flexCenterSx, height: windowSize.appInnerHeight }}>
      <CircularProgress sx={{ color: theme.palette.tertiary.main }} />
    </Box>
  );
};
