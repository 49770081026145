import { MailOutline, Facebook, LiveHelp } from '@mui/icons-material';
import { IconLink } from '../IconLink/IconLink';
import { Box, useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { flexCenterSx, iconLinkSx } from '../../styles/Global.style';
import { APP_PATHS } from '../../constants/paths';
import { useNavigate } from 'react-router-dom';

export const SocialLinks = ({ sx }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ ...flexCenterSx, ...sx }}>
      <IconLink
        icon={Facebook}
        href={Copy.generate('Socials.facebook')}
        sx={iconLinkSx(theme)}
        aria="Facebook link"
      />

      <IconLink
        icon={MailOutline}
        href={Copy.generate('Global.emailLink')}
        sx={iconLinkSx(theme)}
        aria="email link"
      />

      <LiveHelp
        onClick={() => navigate(APP_PATHS.help)}
        sx={iconLinkSx(theme)}
        aria-label="help link"
      />
    </Box>
  );
};
