import { Copy } from '../copy/Copy';

export const FEEDBACK_INIT_VALUES = {
  email: '',
  feedbackReason: '',
  feedback: '',
};

export const FEEDBACK_SELECT_OPTIONS = [
  {
    label: Copy.generate('Feedback.feedbackReason.like'),
    value: 'LIKE',
  },
  {
    label: Copy.generate('Feedback.feedbackReason.dislike'),
    value: 'DISLIKE',
  },
  {
    label: Copy.generate('Feedback.feedbackReason.fundraising'),
    value: 'FUNDRAISING IDEA',
  },
  {
    label: Copy.generate('Feedback.feedbackReason.other'),
    value: 'OTHER',
  },
];
