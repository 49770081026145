import { Box } from '@mui/material';

export const Logo = ({ width, height }) => {
  return (
    <Box component="div" sx={{ display: 'flex', width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        id="Layer_1"
        x={0}
        y={0}
        style={{
          enableBackground: 'new 0 0 841.9 595.3',
          margin: '8px',
        }}
        viewBox="308 245.3 105.2 105.2"
        height={50}
      >
        <style>
          {
            '.st0{fill:#00bbf9}.st1{fill:#f15bb5}.st2{fill:#fee440}.st3{fill:#f15bb5}.st3,.st5,.st6{clip-path:url(#SVGID_2_)}.st5{fill:#00bbf9}.st6,.st7{fill:#fee440}.st7,.st8,.st9{clip-path:url(#SVGID_4_)}.st8{fill:#00bbf9}.st9{fill:#f15bb5}'
          }
        </style>
        <switch>
          <g>
            <path
              d="M360.6 350.5c29 0 52.6-23.5 52.6-52.6 0-29-23.5-52.6-52.6-52.6-29 0-52.6 23.5-52.6 52.6 0 29 23.5 52.6 52.6 52.6z"
              className="st0"
            />
            <path
              d="M360.6 346.4c26.8 0 48.5-21.7 48.5-48.5s-21.7-48.5-48.5-48.5-48.5 21.7-48.5 48.5 21.7 48.5 48.5 48.5z"
              className="st1"
            />
            <path
              d="M360.6 346.4v.4c27 0 49-21.9 49-49s-21.9-49-49-49c-27 0-49 21.9-49 49s21.9 49 49 49v-.8c-13.3 0-25.3-5.4-34-14.1s-14.1-20.7-14.1-34 5.4-25.3 14.1-34 20.7-14.1 34-14.1 25.3 5.4 34 14.1 14.1 20.7 14.1 34-5.4 25.3-14.1 34-20.7 14.1-34 14.1v.4z"
              className="st2"
            />
            <path
              d="M360.6 341.9c24.3 0 44-19.7 44-44s-19.7-44-44-44-44 19.7-44 44 19.7 44 44 44z"
              className="st0"
            />
            <defs>
              <path
                id="SVGID_1_"
                d="M360.6 341.9c24.3 0 44-19.7 44-44s-19.7-44-44-44-44 19.7-44 44 19.7 44 44 44"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use
                xlinkHref="#SVGID_1_"
                style={{
                  overflow: 'visible',
                }}
              />
            </clipPath>
            <path
              d="M360.7 298.4 337.4 258c7.4-4.3 14.8-6.3 23.3-6.3v46.7z"
              className="st3"
            />
            <path
              d="m360.7 298.4 1.4-.8-23.3-40.4-1.4.8.8 1.4c7.2-4.2 14.2-6 22.5-6v-1.6h-1.6v46.7h1.6l1.4-.8-1.4.7h1.6V250h-1.6c-8.8 0-16.5 2-24.2 6.5l-1.4.8 27.2 47.2v-6.1h-1.6z"
              style={{
                clipPath: 'url(#SVGID_2_)',
                fill: '#fee43e',
              }}
            />
            <path
              d="M360.9 298.3 320.5 275c4.3-7.4 9.7-12.8 17.1-17.1l23.3 40.4z"
              className="st5"
            />
            <path
              d="m360.9 298.3.8-1.4-40.4-23.3-.8 1.4 1.4.8c4.2-7.2 9.3-12.3 16.5-16.5l-.8-1.4-1.4.8 23.3 40.4 1.4-.8.8-1.4-.8 1.4 1.4-.8-24.2-41.8-1.4.8c-7.7 4.4-13.3 10-17.7 17.7l-.8 1.4 47.2 27.2-3.1-5.3-1.4.8z"
              className="st6"
            />
            <path
              d="M361 298h-46.7c0-8.6 2-15.9 6.3-23.3L361 298z"
              className="st3"
            />
            <path
              d="M361 298v-1.6h-46.7v1.6h1.6c0-8.3 1.9-15.3 6-22.5l-1.4-.8-.8 1.4 40.4 23.3.9-1.4v-1.6 1.6l.8-1.4-41.8-24.1-.8 1.4c-4.4 7.6-6.5 15.3-6.5 24.2v1.6h54.4l-5.3-3.1-.8 1.4z"
              className="st6"
            />
            <path
              d="m361 297.8-40.4 23.3c-4.3-7.4-6.3-14.8-6.3-23.3H361z"
              className="st5"
            />
            <path
              d="m361 297.8-.8-1.4-40.4 23.3.8 1.4 1.4-.8c-4.2-7.2-6-14.2-6-22.5h-1.6v1.6H361v-1.6l-.8-1.4.8 1.4v-1.6h-48.3v1.6c0 8.8 2 16.5 6.5 24.2l.8 1.4 47.2-27.2H361v1.6z"
              className="st6"
            />
            <path
              d="M360.9 297.6 337.6 338c-7.4-4.3-12.8-9.7-17.1-17.1l40.4-23.3z"
              className="st3"
            />
            <path
              d="m360.9 297.6-1.4-.8-23.3 40.4 1.4.8.8-1.4c-7.2-4.2-12.3-9.3-16.5-16.5l-1.4.8.8 1.4 40.4-23.3-.8-1.4-1.4-.8 1.4.8-.8-1.4-41.8 24.2.8 1.4c4.4 7.7 10 13.3 17.7 17.7l1.4.8 27.2-47.2-5.3 3.1.8 1.4z"
              className="st6"
            />
            <path
              d="M360.7 297.5v46.7c-8.6 0-15.9-2-23.3-6.3l23.3-40.4z"
              className="st5"
            />
            <path
              d="M360.7 297.5h-1.6v46.7h1.6v-1.6c-8.3 0-15.3-1.9-22.5-6l-.8 1.4 1.4.8 23.3-40.4-1.4-.9h-1.6 1.6l-1.4-.8-24.2 41.8 1.4.8c7.6 4.4 15.3 6.5 24.2 6.5h1.6v-54.4l-3.1 5.3 1.5.8z"
              className="st6"
            />
            <path
              d="m360.5 297.5 23.3 40.4c-7.4 4.3-14.8 6.3-23.3 6.3v-46.7z"
              className="st3"
            />
            <path
              d="m360.5 297.5-1.4.8 23.3 40.4 1.4-.8-.8-1.4c-7.2 4.2-14.2 6-22.5 6v1.6h1.6v-46.7h-1.6l-1.4.8 1.4-.7h-1.6v48.3h1.6c8.8 0 16.5-2 24.2-6.5l1.4-.8-27.2-47.2v6.1h1.6v.1z"
              className="st6"
            />
            <path
              d="m360.3 297.6 40.4 23.3c-4.3 7.4-9.7 12.8-17.1 17.1l-23.3-40.4z"
              className="st5"
            />
            <path
              d="m360.3 297.6-.8 1.4 40.4 23.3.8-1.4-1.4-.8c-4.2 7.2-9.3 12.3-16.5 16.5l.8 1.4 1.4-.8-23.3-40.4-1.4.8-.8 1.4.8-1.4-1.4.8 24.2 41.8 1.4-.8c7.7-4.4 13.3-10 17.7-17.7l.8-1.4-47.2-27.2 3.1 5.3 1.4-.8z"
              className="st6"
            />
            <path
              d="M360.1 297.8h46.7c0 8.6-2 15.9-6.3 23.3l-40.4-23.3z"
              className="st3"
            />
            <path
              d="M360.1 297.8v1.6h46.7v-1.6h-1.6c0 8.3-1.9 15.3-6 22.5l1.4.8.8-1.4-40.4-23.3-.9 1.4v1.6-1.6l-.8 1.4 41.8 24.2.8-1.4c4.4-7.6 6.5-15.3 6.5-24.2v-1.6H354l5.3 3.1.8-1.5z"
              className="st6"
            />
            <path
              d="m360.1 298 40.4-23.3c4.3 7.4 6.3 14.8 6.3 23.3h-46.7z"
              className="st5"
            />
            <path
              d="m360.1 298 .8 1.4 40.4-23.3-.8-1.4-1.4.8c4.2 7.2 6 14.2 6 22.5h1.6v-1.6H360v1.6l.8 1.4-.7-1.4v1.6h48.3V298c0-8.8-2-16.5-6.5-24.2l-.8-1.4-47.1 27.3h6.1V298z"
              className="st6"
            />
            <path
              d="m360.3 298.3 23.3-40.4c7.4 4.3 12.8 9.7 17.1 17.1l-40.4 23.3z"
              className="st3"
            />
            <path
              d="m360.3 298.3 1.4.8 23.3-40.4-1.4-.8-.8 1.4c7.2 4.2 12.3 9.3 16.5 16.5l1.4-.8-.8-1.4-40.4 23.3.8 1.4 1.4.8-1.4-.8.8 1.4 41.8-24.2-.8-1.4c-4.4-7.7-10-13.3-17.7-17.7l-1.4-.8-27.2 47.2 5.3-3.1-.8-1.4z"
              className="st6"
            />
            <path
              d="M360.5 298.4v-46.7c8.6 0 15.9 2 23.3 6.3l-23.3 40.4z"
              className="st5"
            />
            <path
              d="M360.5 298.4h1.6v-46.7h-1.6v1.6c8.3 0 15.3 1.9 22.5 6l.8-1.4-1.4-.8-23.4 40.5 1.5.8h1.6-1.6l1.4.8 24.2-41.8-1.4-.8c-7.6-4.4-15.3-6.5-24.2-6.5h-1.6v54.4l3.1-5.3-1.5-.8z"
              className="st6"
            />
            <path
              d="M360.6 341.9v.4c24.5 0 44.4-19.9 44.4-44.4s-19.9-44.4-44.4-44.4-44.4 19.9-44.4 44.4 19.9 44.4 44.4 44.4v-.8c-12 0-22.9-4.9-30.8-12.8-7.9-7.9-12.8-18.8-12.8-30.8s4.9-22.9 12.8-30.8c7.9-7.9 18.8-12.8 30.8-12.8s22.9 4.9 30.8 12.8c7.9 7.9 12.8 18.8 12.8 30.8s-4.9 22.9-12.8 30.8c-7.9 7.9-18.8 12.8-30.8 12.8v.4z"
              className="st2"
            />
            <path
              d="M360.6 319.5c11.9 0 21.6-9.7 21.6-21.6 0-11.9-9.7-21.6-21.6-21.6-11.9 0-21.6 9.7-21.6 21.6 0 11.9 9.7 21.6 21.6 21.6z"
              className="st0"
            />
            <path
              d="M360.6 319.5v.4c12.2 0 22-9.9 22-22 0-12.2-9.9-22-22-22-12.2 0-22 9.9-22 22 0 12.2 9.9 22 22 22v-.9c-5.8 0-11.1-2.4-15-6.2-3.8-3.8-6.2-9.1-6.2-15 0-5.8 2.4-11.1 6.2-15 3.8-3.8 9.1-6.2 15-6.2 5.8 0 11.1 2.4 15 6.2 3.8 3.8 6.2 9.1 6.2 15 0 5.8-2.4 11.1-6.2 15-3.8 3.8-9.1 6.2-15 6.2v.5z"
              className="st2"
            />
            <path
              d="M360.6 315.4c9.7 0 17.5-7.8 17.5-17.5s-7.8-17.5-17.5-17.5-17.5 7.8-17.5 17.5c0 9.6 7.8 17.5 17.5 17.5z"
              className="st1"
            />
            <path
              d="M360.6 315.4v.4c9.9 0 18-8 18-18 0-9.9-8-18-18-18-9.9 0-18 8-18 18 0 9.9 8 18 18 18v-.8c-4.7 0-9-1.9-12.1-5-3.1-3.1-5-7.4-5-12.1s1.9-9 5-12.1c3.1-3.1 7.4-5 12.1-5s9 1.9 12.1 5c3.1 3.1 5 7.4 5 12.1 0 4.7-1.9 9-5 12.1-3.1 3.1-7.4 5-12.1 5v.4z"
              className="st2"
            />
            <path
              d="M360.6 312.3c7.9 0 14.4-6.4 14.4-14.4s-6.4-14.4-14.4-14.4-14.4 6.4-14.4 14.4c0 7.9 6.4 14.4 14.4 14.4z"
              className="st0"
            />
            <defs>
              <path
                id="SVGID_3_"
                d="M360.6 312.3c7.9 0 14.4-6.4 14.4-14.4s-6.4-14.4-14.4-14.4-14.4 6.4-14.4 14.4c0 7.9 6.4 14.4 14.4 14.4"
              />
            </defs>
            <clipPath id="SVGID_4_">
              <use
                xlinkHref="#SVGID_3_"
                style={{
                  overflow: 'visible',
                }}
              />
            </clipPath>
            <path
              d="m360.5 298-9.5-11.3c2.8-2.3 5.8-3.4 9.5-3.4V298z"
              className="st7"
            />
            <path
              d="m360.5 298-14.5-2.6c.6-3.6 2.3-6.4 5-8.7l9.5 11.3z"
              className="st8"
            />
            <path
              d="m360.5 298-12.7 7.4c-1.8-3.1-2.4-6.4-1.7-9.9l14.4 2.5z"
              className="st9"
            />
            <path
              d="m360.5 298-5 13.8c-3.4-1.2-5.9-3.3-7.7-6.5l12.7-7.3z"
              className="st7"
            />
            <path
              d="m360.5 298 5 13.8c-3.4 1.2-6.7 1.2-10.1 0l5.1-13.8z"
              className="st8"
            />
            <path
              d="m360.5 298 12.7 7.4c-1.8 3.1-4.3 5.2-7.7 6.5l-5-13.9z"
              className="st9"
            />
            <path
              d="m360.5 298 14.5-2.6c.6 3.5.1 6.8-1.7 9.9l-12.8-7.3z"
              className="st7"
            />
            <path
              d="m360.5 298 9.4-11.3c2.8 2.3 4.4 5.2 5 8.7l-14.4 2.6z"
              className="st8"
            />
            <path
              d="M360.5 298v-14.7c3.6 0 6.7 1.1 9.4 3.4l-9.4 11.3z"
              className="st9"
            />
            <path
              d="M360.6 312.3v.4c8.2 0 14.8-6.6 14.8-14.8 0-8.2-6.6-14.8-14.8-14.8-8.2 0-14.8 6.6-14.8 14.8 0 8.2 6.6 14.8 14.8 14.8v-.8c-3.9 0-7.3-1.6-9.9-4.1-2.5-2.5-4.1-6-4.1-9.9 0-3.9 1.6-7.3 4.1-9.9 2.5-2.5 6-4.1 9.9-4.1 3.9 0 7.3 1.6 9.9 4.1 2.5 2.5 4.1 6 4.1 9.9 0 3.9-1.6 7.3-4.1 9.9-2.5 2.5-6 4.1-9.9 4.1v.4z"
              className="st2"
            />
          </g>
        </switch>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Layer_1"
        height={65}
        x={0}
        y={0}
        style={{
          maxHeight: 500,
        }}
        viewBox="416.048 245.3 117.052 112.634"
      >
        <style>
          {
            '.st0{fill:#00bbf9}.st1{fill:#f15bb5}.st2{fill:#fee440}.st3{fill:#f15bb5}.st3,.st5,.st6{clip-path:url(#SVGID_2_)}.st5{fill:#00bbf9}.st6,.st7{fill:#fee440}.st7,.st8,.st9{clip-path:url(#SVGID_4_)}.st8{fill:#00bbf9}.st9{fill:#f15bb5}'
          }
        </style>
        <switch>
          <g>
            <path
              d="M360.6 350.5c29 0 52.6-23.5 52.6-52.6 0-29-23.5-52.6-52.6-52.6-29 0-52.6 23.5-52.6 52.6 0 29 23.5 52.6 52.6 52.6z"
              className="st0"
            />
            <path
              d="M360.6 346.4c26.8 0 48.5-21.7 48.5-48.5s-21.7-48.5-48.5-48.5-48.5 21.7-48.5 48.5 21.7 48.5 48.5 48.5z"
              className="st1"
            />
            <path
              d="M360.6 346.4v.4c27 0 49-21.9 49-49s-21.9-49-49-49c-27 0-49 21.9-49 49s21.9 49 49 49v-.8c-13.3 0-25.3-5.4-34-14.1s-14.1-20.7-14.1-34 5.4-25.3 14.1-34 20.7-14.1 34-14.1 25.3 5.4 34 14.1 14.1 20.7 14.1 34-5.4 25.3-14.1 34-20.7 14.1-34 14.1v.4z"
              className="st2"
            />
            <path
              d="M360.6 341.9c24.3 0 44-19.7 44-44s-19.7-44-44-44-44 19.7-44 44 19.7 44 44 44z"
              className="st0"
            />
            <defs>
              <path
                id="SVGID_1_"
                d="M360.6 341.9c24.3 0 44-19.7 44-44s-19.7-44-44-44-44 19.7-44 44 19.7 44 44 44"
              />
            </defs>
            <clipPath id="SVGID_2_" />
            <path
              d="M360.7 298.4 337.4 258c7.4-4.3 14.8-6.3 23.3-6.3v46.7z"
              className="st3"
            />
            <path
              d="m360.7 298.4 1.4-.8-23.3-40.4-1.4.8.8 1.4c7.2-4.2 14.2-6 22.5-6v-1.6h-1.6v46.7h1.6l1.4-.8-1.4.7h1.6V250h-1.6c-8.8 0-16.5 2-24.2 6.5l-1.4.8 27.2 47.2v-6.1h-1.6z"
              style={{
                clipPath: 'url(#SVGID_2_)',
                fill: '#f15bb5',
              }}
            />
            <path
              d="M360.9 298.3 320.5 275c4.3-7.4 9.7-12.8 17.1-17.1l23.3 40.4z"
              className="st5"
            />
            <path
              d="m360.9 298.3.8-1.4-40.4-23.3-.8 1.4 1.4.8c4.2-7.2 9.3-12.3 16.5-16.5l-.8-1.4-1.4.8 23.3 40.4 1.4-.8.8-1.4-.8 1.4 1.4-.8-24.2-41.8-1.4.8c-7.7 4.4-13.3 10-17.7 17.7l-.8 1.4 47.2 27.2-3.1-5.3-1.4.8z"
              className="st6"
            />
            <path
              d="M361 298h-46.7c0-8.6 2-15.9 6.3-23.3L361 298z"
              className="st3"
            />
            <path
              d="M361 298v-1.6h-46.7v1.6h1.6c0-8.3 1.9-15.3 6-22.5l-1.4-.8-.8 1.4 40.4 23.3.9-1.4v-1.6 1.6l.8-1.4-41.8-24.1-.8 1.4c-4.4 7.6-6.5 15.3-6.5 24.2v1.6h54.4l-5.3-3.1-.8 1.4z"
              className="st6"
            />
            <path
              d="m361 297.8-40.4 23.3c-4.3-7.4-6.3-14.8-6.3-23.3H361z"
              className="st5"
            />
            <path
              d="m361 297.8-.8-1.4-40.4 23.3.8 1.4 1.4-.8c-4.2-7.2-6-14.2-6-22.5h-1.6v1.6H361v-1.6l-.8-1.4.8 1.4v-1.6h-48.3v1.6c0 8.8 2 16.5 6.5 24.2l.8 1.4 47.2-27.2H361v1.6z"
              className="st6"
            />
            <path
              d="M360.9 297.6 337.6 338c-7.4-4.3-12.8-9.7-17.1-17.1l40.4-23.3z"
              className="st3"
            />
            <path
              d="m360.9 297.6-1.4-.8-23.3 40.4 1.4.8.8-1.4c-7.2-4.2-12.3-9.3-16.5-16.5l-1.4.8.8 1.4 40.4-23.3-.8-1.4-1.4-.8 1.4.8-.8-1.4-41.8 24.2.8 1.4c4.4 7.7 10 13.3 17.7 17.7l1.4.8 27.2-47.2-5.3 3.1.8 1.4z"
              className="st6"
            />
            <path
              d="M360.7 297.5v46.7c-8.6 0-15.9-2-23.3-6.3l23.3-40.4z"
              className="st5"
            />
            <path
              d="M360.7 297.5h-1.6v46.7h1.6v-1.6c-8.3 0-15.3-1.9-22.5-6l-.8 1.4 1.4.8 23.3-40.4-1.4-.9h-1.6 1.6l-1.4-.8-24.2 41.8 1.4.8c7.6 4.4 15.3 6.5 24.2 6.5h1.6v-54.4l-3.1 5.3 1.5.8z"
              className="st6"
            />
            <path
              d="m360.5 297.5 23.3 40.4c-7.4 4.3-14.8 6.3-23.3 6.3v-46.7z"
              className="st3"
            />
            <path
              d="m360.5 297.5-1.4.8 23.3 40.4 1.4-.8-.8-1.4c-7.2 4.2-14.2 6-22.5 6v1.6h1.6v-46.7h-1.6l-1.4.8 1.4-.7h-1.6v48.3h1.6c8.8 0 16.5-2 24.2-6.5l1.4-.8-27.2-47.2v6.1h1.6v.1z"
              className="st6"
            />
            <path
              d="m360.3 297.6 40.4 23.3c-4.3 7.4-9.7 12.8-17.1 17.1l-23.3-40.4z"
              className="st5"
            />
            <path
              d="m360.3 297.6-.8 1.4 40.4 23.3.8-1.4-1.4-.8c-4.2 7.2-9.3 12.3-16.5 16.5l.8 1.4 1.4-.8-23.3-40.4-1.4.8-.8 1.4.8-1.4-1.4.8 24.2 41.8 1.4-.8c7.7-4.4 13.3-10 17.7-17.7l.8-1.4-47.2-27.2 3.1 5.3 1.4-.8z"
              className="st6"
            />
            <path
              d="M360.1 297.8h46.7c0 8.6-2 15.9-6.3 23.3l-40.4-23.3z"
              className="st3"
            />
            <path
              d="M360.1 297.8v1.6h46.7v-1.6h-1.6c0 8.3-1.9 15.3-6 22.5l1.4.8.8-1.4-40.4-23.3-.9 1.4v1.6-1.6l-.8 1.4 41.8 24.2.8-1.4c4.4-7.6 6.5-15.3 6.5-24.2v-1.6H354l5.3 3.1.8-1.5z"
              className="st6"
            />
            <path
              d="m360.1 298 40.4-23.3c4.3 7.4 6.3 14.8 6.3 23.3h-46.7z"
              className="st5"
            />
            <path
              d="m360.1 298 .8 1.4 40.4-23.3-.8-1.4-1.4.8c4.2 7.2 6 14.2 6 22.5h1.6v-1.6H360v1.6l.8 1.4-.7-1.4v1.6h48.3V298c0-8.8-2-16.5-6.5-24.2l-.8-1.4-47.1 27.3h6.1V298z"
              className="st6"
            />
            <path
              d="m360.3 298.3 23.3-40.4c7.4 4.3 12.8 9.7 17.1 17.1l-40.4 23.3z"
              className="st3"
            />
            <path
              d="m360.3 298.3 1.4.8 23.3-40.4-1.4-.8-.8 1.4c7.2 4.2 12.3 9.3 16.5 16.5l1.4-.8-.8-1.4-40.4 23.3.8 1.4 1.4.8-1.4-.8.8 1.4 41.8-24.2-.8-1.4c-4.4-7.7-10-13.3-17.7-17.7l-1.4-.8-27.2 47.2 5.3-3.1-.8-1.4z"
              className="st6"
            />
            <path
              d="M360.5 298.4v-46.7c8.6 0 15.9 2 23.3 6.3l-23.3 40.4z"
              className="st5"
            />
            <path
              d="M360.5 298.4h1.6v-46.7h-1.6v1.6c8.3 0 15.3 1.9 22.5 6l.8-1.4-1.4-.8-23.4 40.5 1.5.8h1.6-1.6l1.4.8 24.2-41.8-1.4-.8c-7.6-4.4-15.3-6.5-24.2-6.5h-1.6v54.4l3.1-5.3-1.5-.8z"
              className="st6"
            />
            <path
              d="M360.6 341.9v.4c24.5 0 44.4-19.9 44.4-44.4s-19.9-44.4-44.4-44.4-44.4 19.9-44.4 44.4 19.9 44.4 44.4 44.4v-.8c-12 0-22.9-4.9-30.8-12.8-7.9-7.9-12.8-18.8-12.8-30.8s4.9-22.9 12.8-30.8c7.9-7.9 18.8-12.8 30.8-12.8s22.9 4.9 30.8 12.8c7.9 7.9 12.8 18.8 12.8 30.8s-4.9 22.9-12.8 30.8c-7.9 7.9-18.8 12.8-30.8 12.8v.4z"
              className="st2"
            />
            <path
              d="M360.6 319.5c11.9 0 21.6-9.7 21.6-21.6 0-11.9-9.7-21.6-21.6-21.6-11.9 0-21.6 9.7-21.6 21.6 0 11.9 9.7 21.6 21.6 21.6z"
              className="st0"
            />
            <path
              d="M360.6 319.5v.4c12.2 0 22-9.9 22-22 0-12.2-9.9-22-22-22-12.2 0-22 9.9-22 22 0 12.2 9.9 22 22 22v-.9c-5.8 0-11.1-2.4-15-6.2-3.8-3.8-6.2-9.1-6.2-15 0-5.8 2.4-11.1 6.2-15 3.8-3.8 9.1-6.2 15-6.2 5.8 0 11.1 2.4 15 6.2 3.8 3.8 6.2 9.1 6.2 15 0 5.8-2.4 11.1-6.2 15-3.8 3.8-9.1 6.2-15 6.2v.5z"
              className="st2"
            />
            <path
              d="M360.6 315.4c9.7 0 17.5-7.8 17.5-17.5s-7.8-17.5-17.5-17.5-17.5 7.8-17.5 17.5c0 9.6 7.8 17.5 17.5 17.5z"
              className="st1"
            />
            <path
              d="M360.6 315.4v.4c9.9 0 18-8 18-18 0-9.9-8-18-18-18-9.9 0-18 8-18 18 0 9.9 8 18 18 18v-.8c-4.7 0-9-1.9-12.1-5-3.1-3.1-5-7.4-5-12.1s1.9-9 5-12.1c3.1-3.1 7.4-5 12.1-5s9 1.9 12.1 5c3.1 3.1 5 7.4 5 12.1 0 4.7-1.9 9-5 12.1-3.1 3.1-7.4 5-12.1 5v.4z"
              className="st2"
            />
            <path
              d="M360.6 312.3c7.9 0 14.4-6.4 14.4-14.4s-6.4-14.4-14.4-14.4-14.4 6.4-14.4 14.4c0 7.9 6.4 14.4 14.4 14.4z"
              className="st0"
            />
            <defs>
              <path
                id="SVGID_3_"
                d="M360.6 312.3c7.9 0 14.4-6.4 14.4-14.4s-6.4-14.4-14.4-14.4-14.4 6.4-14.4 14.4c0 7.9 6.4 14.4 14.4 14.4"
              />
            </defs>
            <clipPath id="SVGID_4_" />
            <path
              d="m360.5 298-9.5-11.3c2.8-2.3 5.8-3.4 9.5-3.4V298z"
              className="st7"
            />
            <path
              d="m360.5 298-14.5-2.6c.6-3.6 2.3-6.4 5-8.7l9.5 11.3z"
              className="st8"
            />
            <path
              d="m360.5 298-12.7 7.4c-1.8-3.1-2.4-6.4-1.7-9.9l14.4 2.5z"
              className="st9"
            />
            <path
              d="m360.5 298-5 13.8c-3.4-1.2-5.9-3.3-7.7-6.5l12.7-7.3z"
              className="st7"
            />
            <path
              d="m360.5 298 5 13.8c-3.4 1.2-6.7 1.2-10.1 0l5.1-13.8z"
              className="st8"
            />
            <path
              d="m360.5 298 12.7 7.4c-1.8 3.1-4.3 5.2-7.7 6.5l-5-13.9z"
              className="st9"
            />
            <path
              d="m360.5 298 14.5-2.6c.6 3.5.1 6.8-1.7 9.9l-12.8-7.3z"
              className="st7"
            />
            <path
              d="m360.5 298 9.4-11.3c2.8 2.3 4.4 5.2 5 8.7l-14.4 2.6z"
              className="st8"
            />
            <path
              d="M360.5 298v-14.7c3.6 0 6.7 1.1 9.4 3.4l-9.4 11.3z"
              className="st9"
            />
            <path
              d="M360.6 312.3v.4c8.2 0 14.8-6.6 14.8-14.8 0-8.2-6.6-14.8-14.8-14.8-8.2 0-14.8 6.6-14.8 14.8 0 8.2 6.6 14.8 14.8 14.8v-.8c-3.9 0-7.3-1.6-9.9-4.1-2.5-2.5-4.1-6-4.1-9.9 0-3.9 1.6-7.3 4.1-9.9 2.5-2.5 6-4.1 9.9-4.1 3.9 0 7.3 1.6 9.9 4.1 2.5 2.5 4.1 6 4.1 9.9 0 3.9-1.6 7.3-4.1 9.9-2.5 2.5-6 4.1-9.9 4.1v.4z"
              className="st2"
            />
            <path
              d="M426.1 248.1c.3-.1.9-.1 1.4-.1.7 0 1.2.1 1.6.3.3.2.6.6.6 1 0 .5-.3 1-.9 1.2.6.1 1.1.6 1.1 1.3 0 .5-.2.9-.5 1.1-.4.3-1 .5-2 .5-.5 0-1 0-1.2-.1v-5.2zm1 2.1h.5c.7 0 1.1-.3 1.1-.8s-.4-.7-1-.7h-.6v1.5zm0 2.5h.5c.6 0 1.2-.2 1.2-.9 0-.6-.5-.9-1.2-.9h-.5v1.8z"
              className="st1"
            />
            <path
              d="m437.4 251.9-.5 1.5h-1l1.7-5.4h1.3l1.8 5.4h-1.1l-.5-1.5h-1.7zm1.5-.7-.4-1.3c-.1-.3-.2-.7-.3-1-.1.3-.2.7-.3 1l-.4 1.3h1.4z"
              className="st0"
            />
            <path d="M447 248h1v4.6h2.2v.8H447V248z" className="st2" />
            <path
              d="M457.9 248h1v3.5c0 1.5-.7 2-1.8 2-.3 0-.6 0-.8-.1l.1-.8c.2 0 .4.1.6.1.6 0 .9-.3.9-1.2V248z"
              className="st1"
            />
            <path
              d="m466.6 251.9-.5 1.5h-1l1.7-5.4h1.3l1.8 5.4h-1.1l-.5-1.5h-1.7zm1.6-.7-.4-1.3c-.1-.3-.2-.7-.3-1-.1.3-.2.7-.3 1l-.4 1.3h1.4z"
              className="st0"
            />
            <path
              d="M476.3 248h3.1v.8h-2.1v1.5h2v.8h-2v2.3h-1V248z"
              className="st2"
            />
            <path
              d="M486 248h3.1v.8H487v1.5h2v.8h-2v2.3h-1V248z"
              className="st1"
            />
            <path
              d="M495.7 248.1c.4-.1.9-.1 1.5-.1.8 0 1.3.1 1.7.4.3.2.5.6.5 1.1 0 .7-.5 1.2-1 1.4.4.1.6.5.8 1 .2.7.3 1.3.4 1.5h-1c-.1-.2-.2-.6-.4-1.3-.2-.7-.4-.9-1-1h-.5v2.2h-1v-5.2zm1 2.4h.6c.7 0 1.1-.4 1.1-.9 0-.6-.4-.9-1.1-.9h-.6v1.8z"
              className="st0"
            />
            <path
              d="m507 251.9-.5 1.5h-1l1.7-5.4h1.3l1.8 5.4h-1.1l-.5-1.5H507zm1.6-.7-.4-1.3c-.1-.3-.2-.7-.3-1-.1.3-.2.7-.3 1l-.4 1.3h1.4z"
              className="st2"
            />
            <path
              d="M517.2 253.5v-2.3l-1.7-3.2h1.1l.7 1.4c.2.4.3.7.5 1.1.1-.3.3-.7.5-1.1l.7-1.4h1.1l-1.8 3.1v2.3h-1.1zM436.5 342.1h-17.4v-64.5c4.8-.8 11.6-1.4 21.6-1.4 9.4 0 16.1 1.6 20.7 5.1 1.2.9 2.3 1.9 3.2 3.1V311c-.6.8-1.3 1.5-2 2.2-5.3 4.8-13 6.8-21.8 6.8-1.7 0-3.2-.1-4.3-.2v22.3m5.2-52.7c-2.6 0-4.4.2-5.2.4v16.7c1 .1 2.1.2 4 .2 6.9 0 11.2-3.5 11.2-9.1-.1-4.9-3.4-8.2-10-8.2"
              className="st1"
            />
            <path
              d="M478.9 323.9h-14.3V311c2.8-3.6 4.3-8.5 4.3-13.9 0-4.9-1.6-9.4-4.3-12.7v-11.3h-16.7v-14.7h51.3v14.7h-17v39.5l-3.3 11.3"
              style={{
                fill: '#00bbf9',
              }}
            />
            <path
              d="M464.6 311v-26.6c2.6 3.3 4.3 7.8 4.3 12.7 0 5.4-1.5 10.3-4.3 13.9"
              style={{
                fill: '#f15bb5',
              }}
            />
            <path
              d="M533.1 350.2h-18.9l-4.2-15.1h-16.9l-3.7 15.1h-18.2l7.7-26.3h3.3v-11.2l8.1-27.9h23.3l19.5 65.4m-31.9-52.8c-1 3.9-1.9 9.3-2.9 13l-2.8 11.6h12.1l-3-11.7c-1-3.7-2.2-9-3.2-12.9h-.2"
              className="st2"
            />
            <path
              d="M482.2 323.9h-3.3l3.3-11.3v11.3"
              style={{
                fill: '#00bbf9',
              }}
            />
          </g>
        </switch>
      </svg>
    </Box>
  );
};
