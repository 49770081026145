export const contactContainerSx = (theme) => ({
  maxWidth: '1100px',
  height: '100%',
  margin: 'auto',
  padding: '80px 20px',
  display: 'flex',
  [theme.breakpoints.between('xs', 'sm')]: {
    flexDirection: 'column',
    padding: '20px 20px 80px 20px',
  },
});

export const getInTouchContainerSx = (theme) => ({
  flex: '1 0 50%',
  paddingRight: '48px',
  [theme.breakpoints.between('xs', 'sm')]: {
    paddingRight: '0px',
  },
});

export const contactUsFormContainerSx = {
  flex: '1 0 50%',
};
