import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Accordion } from '../../components/Accordion/Accordion';
import { HELP_ACCORDIONS } from '../../constants/helpAccordions';
import { Button } from '../../components/Button/Button';
import { SCHOOL_LINK } from '../../constants/links';
import { buttonSx } from '../../styles/Global.style';
import { accordionContainerSx } from './Help.style';
import { useNavigate } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';

export const Help = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <>
      <ContentCard
        title={Copy.generate('Help.school.title')}
        heading={Copy.inject('Help.school.heading', {
          school: dynamicTextColor('school', theme.palette.quaternary.main),
        })}
        content={Copy.generate('Help.school.content')}
        isColumn
        isTop
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.contactSchool')}
            onClick={() => window.open(SCHOOL_LINK)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>

      <ContentCard
        title={Copy.generate('Help.questions.title')}
        heading={Copy.inject('Help.questions.heading', {
          pta: dynamicTextColor(
            'Baljaffray PTA.',
            theme.palette.quaternary.main,
          ),
        })}
        isColumn
        isSecondaryCard
      >
        <Box sx={accordionContainerSx}>
          {HELP_ACCORDIONS.map((a, i) => (
            <Accordion key={i} heading={a.heading} content={a.content} />
          ))}
        </Box>
      </ContentCard>

      <ContentCard
        title={Copy.generate('Help.feedback.title')}
        heading={Copy.inject('Help.feedback.heading', {
          love: dynamicTextColor('love to hear', theme.palette.quaternary.main),
        })}
        isColumn
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.feedback')}
            onClick={() => navigate(APP_PATHS.feedback)}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>
    </>
  );
};
