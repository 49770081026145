import { Copy } from '../copy/Copy';
import { APP_PATHS } from './paths';
import { Hyperlink } from '../components/Hyperlink/Hyperlink';

export const HELP_ACCORDIONS = [
  {
    heading: Copy.generate('Help.questions.accordions.1.heading'),
    content: Copy.generate('Help.questions.accordions.1.content'),
  },
  {
    heading: Copy.generate('Help.questions.accordions.2.heading'),
    content: Copy.inject('Help.questions.accordions.2.content'),
  },
  {
    heading: Copy.generate('Help.questions.accordions.3.heading'),
    content: Copy.inject('Help.questions.accordions.3.content', {
      interested: (
        <Hyperlink
          to={APP_PATHS.volunteer}
          text={Copy.generate('Global.form')}
        />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.4.heading'),
    content: Copy.inject('Help.questions.accordions.4.content', {
      interested: (
        <Hyperlink
          to={APP_PATHS.volunteer}
          text={Copy.generate('Global.form')}
        />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.5.heading'),
    content: Copy.inject('Help.questions.accordions.5.content', {
      calendar: (
        <Hyperlink
          to={APP_PATHS.calendar}
          text={Copy.generate('Global.calendar')}
        />
      ),
    }),
  },
  {
    heading: Copy.generate('Help.questions.accordions.6.heading'),
    content: Copy.generate('Help.questions.accordions.6.content'),
  },
];
