import { Fade, List, Stack, useTheme } from '@mui/material';
import { NavListItems } from '../NavListItems/NavListItems';
import {
  navListItemSx,
  navListSx,
  navMenuDrawerSx,
} from './NavMenuDrawer.style';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { SocialLinks } from '../SocialLinks/SocialLinks';
import { Tagline } from '../Tagline/Tagline';
import { flexCenterSx, marginBottom16Sx } from '../../styles/Global.style';

export const NavMenuDrawer = ({ toggleDrawer, sx }) => {
  const theme = useTheme();
  const { windowSize } = useWindowSize();

  return (
    <Fade in={true} timeout={600}>
      <Stack component="nav" sx={{ ...navMenuDrawerSx(theme), ...sx }}>
        <List sx={navListSx(windowSize.appInnerHeight - 48)}>
          <NavListItems sx={navListItemSx} onClick={toggleDrawer} />
        </List>

        <SocialLinks sx={marginBottom16Sx} />
        <Tagline sx={flexCenterSx} />
      </Stack>
    </Fade>
  );
};
