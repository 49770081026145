import { useTheme } from '@emotion/react';
import { AppBar, Box, List, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_PATHS } from '../../constants/paths';
import { Burger } from '../../icons/Burger/Burger';
import { Close } from '../../icons/Close/Close';
import { Logo } from '../../icons/Logo/Logo';
import { IconBtn } from '../IconBtn/IconBtn';
import { NavListItems } from '../NavListItems/NavListItems';
import {
  appBarSx,
  navListSx,
  navListContainerSx,
  logoSx,
  navListItemSx,
  toolbarSx,
  burgerSx,
} from './NavBar.style';

export const NavBar = ({ isNavMenuOpen, toggleMenuBtn, isMobile }) => {
  const theme = useTheme();
  const color = theme.palette.secondary.main;
  const backgroundColor = theme.palette.background.default;

  return (
    <AppBar position="sticky" sx={{ ...appBarSx, backgroundColor }}>
      <Toolbar disableGutters sx={toolbarSx}>
        <Link
          to={APP_PATHS.home}
          style={logoSx}
          onClick={isNavMenuOpen && toggleMenuBtn}
          aria-label="Link to Home page"
        >
          <Logo />
        </Link>

        {isMobile && (
          <Box sx={burgerSx}>
            <IconBtn
              icon={isNavMenuOpen ? <Close /> : <Burger />}
              onClick={toggleMenuBtn}
              color={color}
              aria="Menu button"
            />
          </Box>
        )}

        {!isMobile && (
          <Box sx={navListContainerSx}>
            <List sx={navListSx}>
              <NavListItems sx={navListItemSx} />
            </List>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};
