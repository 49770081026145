import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material';

export const Success = ({ sx }) => {
  const theme = useTheme();

  return (
    <CheckCircleIcon
      fontSize="large"
      sx={{ color: theme.palette.tertiary.main, ...sx }}
    />
  );
};
