import { marginBottom24Sx } from '../../styles/Global.style';

export const containerSx = (theme, isReversed, isColumn, isTop) => ({
  display: 'flex',
  width: '100%',
  maxWidth: '1100px',
  margin: '0 auto',
  [theme.breakpoints.between('xs', 'sm')]: {
    flexDirection: 'column',
    padding: isTop ? '20px 20px 80px 20px' : '80px 20px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    flexDirection: isReversed ? 'row-reverse' : isColumn ? 'column' : 'row',
    padding: '80px 40px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: isReversed ? 'row-reverse' : isColumn ? 'column' : 'row',
    padding: '80px',
  },
});

export const contentContainerSx = (theme, isReversed, isColumn) => ({
  flex: '1 1 50%',
  justifyContent: 'center !important',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('xs')]: {
    margin: '0px 0px 48px 0px',
  },
  [theme.breakpoints.up('sm')]: {
    margin: isReversed
      ? '0 0 0 40px'
      : isColumn
      ? '0px 0px 48px 0px !important'
      : '0 40px 0 0',
  },
});

export const titleSx = {
  letterSpacing: 0,
  marginBottom: '12px',
};

export const headingSx = {
  fontWeight: 800,
  letterSpacing: -0.9,
  ...marginBottom24Sx,
};

export const contentSx = {
  lineHeight: 1.5,
  fontWeight: 500,
  marginBottom: '36px',
};

export const mediaContainerSx = {
  flex: '1 1 50%',
  display: 'flex',
  alignItems: 'center',
};

export const imageContainerSx = (theme) => ({
  [theme.breakpoints.up('xs')]: {
    width: '100%',
  },
  [`@media only screen and (min-width: 450px) and (max-width: ${theme.breakpoints.values.sm}px)`]: {
    width: '80%',
    margin: '0 auto',
  },
});

export const imgSx = {
  maxWidth: '100% !important',
  height: 'auto',
};
