import { Copy } from '../copy/Copy';
import { Marketing } from '../icons/Marketing/Marketing';
import { Fundraise } from '../icons/Fundraise/Fundraise';
import { Teach } from '../icons/Teach/Teach';
import { Volunteer } from '../icons/Volunteer/Volunteer';
import { Support } from '../icons/Support/Support';
import { Events } from '../icons/Events/Events';

export const FEATURES = [
  {
    icon: Support,
    heading: Copy.generate('Home.features.list.1.title'),
    content: Copy.generate('Home.features.list.1.content'),
  },
  {
    icon: Teach,
    heading: Copy.generate('Home.features.list.2.title'),
    content: Copy.generate('Home.features.list.2.content'),
  },
  {
    icon: Marketing,
    heading: Copy.generate('Home.features.list.3.title'),
    content: Copy.generate('Home.features.list.3.content'),
  },
  {
    icon: Events,
    heading: Copy.generate('Home.features.list.4.title'),
    content: Copy.generate('Home.features.list.4.content'),
  },
  {
    icon: Volunteer,
    heading: Copy.generate('Home.features.list.5.title'),
    content: Copy.generate('Home.features.list.5.content'),
  },
  {
    icon: Fundraise,
    heading: Copy.generate('Home.features.list.6.title'),
    content: Copy.generate('Home.features.list.6.content'),
  },
];
