import SchoolIcon from '@mui/icons-material/School';
import { useTheme } from '@mui/material';

export const Teach = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <SchoolIcon fontSize={size} sx={{ color: theme.palette.tertiary.main }} />
  );
};
