import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import { Routes } from '../../routes/routes';
import { PageContainer } from '../PageContainer/PageContainer';
import defaultTheme from '../../themes/defaultTheme';

export const App = () => {
  const [theme, setTheme] = useState();

  useEffect(() => {
    setTheme(defaultTheme);
  }, []);

  if (!theme) {
    return;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PageContainer>
        <Routes />
      </PageContainer>
    </ThemeProvider>
  );
};
