import { Box, useTheme } from '@mui/material';
import { Heading } from '../Heading/Heading';
import { getInTouchContainerSx, getInTouchTitleSx } from './GetInTouch.style';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';

export const GetInTouch = () => {
  const theme = useTheme();

  return (
    <Box
      component="section"
      sx={getInTouchContainerSx}
      className="animate__animated animate__fadeInLeft"
    >
      <Heading
        level={1}
        text={Copy.generate('GetInTouch.title')}
        color={theme.palette.tertiary.main}
        sx={{ letterSpacing: 0, marginBottom: '12px' }}
      />

      <Heading
        text={Copy.inject('GetInTouch.heading', {
          raise: dynamicTextColor(
            'raise money for our school?',
            theme.palette.quaternary.main,
          ),
        })}
        level={2}
        sx={getInTouchTitleSx(theme)}
      />
    </Box>
  );
};
