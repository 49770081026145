export const getInTouchContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '32px',
  height: '80%',
};

export const getInTouchTitleSx = (theme) => ({
  color: theme.palette.secondary.main,
  fontWeight: 800,
  letterSpacing: -0.9,
  marginBottom: '48px',
});

export const giveUsACallContainerSx = {
  marginBottom: '48px',
};

export const giveUsACallSx = (theme) => ({
  color: theme.palette.secondary.main,
  fontWeight: 800,
  fontSize: 24,
  marginBottom: '8px',
});

export const getInTouchTextSx = {
  fontSize: 18,
  fontWeight: 500,
  marginBottom: '16px',
};
