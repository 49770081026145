import { useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';

export const Gallery = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Gallery.intro.title')}
        heading={Copy.inject('Gallery.intro.heading', {
          soon: dynamicTextColor('soon...', quaternary),
        })}
        content={Copy.generate('Gallery.intro.content')}
        isColumn
        isTop
      />
    </>
  );
};
