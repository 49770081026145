import CampaignIcon from '@mui/icons-material/Campaign';
import { useTheme } from '@mui/material';

export const Marketing = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <CampaignIcon fontSize={size} sx={{ color: theme.palette.tertiary.main }} />
  );
};
