import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useTheme } from '@mui/material';

export const Volunteer = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <Diversity3Icon
      fontSize={size}
      sx={{ color: theme.palette.tertiary.main }}
    />
  );
};
