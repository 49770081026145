export const navMenuDrawerSx = (theme) => ({
  bgcolor: theme.palette.primary.main,
  overflow: 'hidden',
});

export const navListSx = (height) => ({
  height: height,
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  marginBottom: '48px',
});

export const navListItemSx = {
  fontSize: '24px  !important',
  fontWeight: 700,
};
