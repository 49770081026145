import { Copy } from '../copy/Copy';

export const NAV_LIST_ITEMS = [
  {
    name: Copy.generate('NavList.listItem.3'),
    to: '/calendar',
  },
  {
    name: Copy.generate('NavList.listItem.1'),
    to: '/events',
  },
  {
    name: Copy.generate('NavList.listItem.2'),
    to: '/fundraising',
  },
  {
    name: Copy.generate('NavList.listItem.5'),
    to: '/volunteer',
  },
  {
    name: Copy.generate('NavList.listItem.4'),
    to: '/help',
  },
];
