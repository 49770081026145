import { Copy } from '../copy/Copy';

export const CONTACT_INIT_VALUES = {
  name: '',
  email: '',
  phone: '',
  interest: '',
  child: '',
  class: '',
};

export const INTEREST_SELECT_OPTIONS = [
  {
    label: Copy.generate('Volunteer.interest.pta'),
    value: 'JOIN PTA',
  },
  {
    label: Copy.generate('Volunteer.interest.volunteer'),
    value: 'VOLUNTEER',
  },
  {
    label: Copy.generate('Volunteer.interest.both'),
    value: 'JOIN PTA OR VOLUNTEER',
  },
];
