import { useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { GoogleCalendar } from '../../components/GoogleCalendar/GoogleCalendar';

export const Calendar = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Calendar.intro.title')}
        heading={Copy.inject('Calendar.intro.heading', {
          on: dynamicTextColor('on?', quaternary),
        })}
        content={Copy.generate('Calendar.intro.content')}
        isColumn
        isTop
      />

      <ContentCard isColumn isSecondaryCard>
        <GoogleCalendar />
      </ContentCard>
    </>
  );
};
