import { useState } from 'react';

export const useImgLoading = () => {
  const [isLoading, setIsLoading] = useState(true);

  const onImgLoad = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return { isLoading, onImgLoad };
};
