import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Button } from '../../components/Button/Button';
import { buttonSx } from '../../styles/Global.style';

export const Events = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Events.intro.title')}
        heading={Copy.inject('Events.intro.heading', {
          book: dynamicTextColor('book your spot today.', quaternary),
        })}
        content={Copy.generate('Events.intro.content')}
        isColumn
        isTop
      />

      <ContentCard
        img="/assets/img/chippy.webp"
        title={Copy.generate('Events.chippie.title')}
        heading={Copy.inject('Events.chippie.heading', {
          chippie: dynamicTextColor(
            `Chippy Hike.`,
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Events.chippie.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.download')}
            onClick={() => window.open('/assets/img/chippyHikeForm.pdf')}
            sx={buttonSx(theme)}
          />
        </Box>
      </ContentCard>
    </>
  );
};
