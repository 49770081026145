import { ListItem as Item, ListItemText, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { listItemSx, listItemTextSx } from './ListItem.style';

export const ListItem = ({ component = Link, text, to, sx, onClick }) => {
  const theme = useTheme();

  return (
    <Item
      component={component}
      to={to}
      sx={listItemSx}
      onClick={onClick}
      disablePadding
    >
      <ListItemText
        primary={text}
        primaryTypographyProps={listItemTextSx({
          theme,
          sx,
        })}
      />
    </Item>
  );
};
