import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTheme } from '@mui/material';

export const Events = ({ size = 'large' }) => {
  const theme = useTheme();

  return (
    <CalendarMonthIcon
      fontSize={size}
      sx={{ color: theme.palette.tertiary.main }}
    />
  );
};
